import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Banner from "../../Components/Banner/Banner";
import ReactTable from "../../Components/ReactTable/ReactTable";
import { CubeSpinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import styles from "./deployment.module.css";
import AccessWrapper from "../../Components/AccessWrapper/AccessWrapper";

import {
  filterByVal,
  getStandardErrMsg,
  isPlatformAdminUser,
} from "../../utils/utils";

import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import useConfirmDelete, {
  useConfirmAction,
} from "../../utils/useConfirmDelete";

import { useDeleteActionMutation } from "../../services/actionsService";
import { useSelector } from "react-redux";
import useFilteredData, { useSearchFilter } from "../../utils/useFilteredData";

import PostPerPage from "../../Components/PostPerPage/PostPerPage";
import SearchFilter from "../../Components/SearchFilter/SearchFilter";
import { AddNewBtn } from "../../Components/Buttons/Buttons";
import { ActionIcon } from "../../Icons/Icons";

import { getCurrentRole } from "../../utils/usePermission";
import {
  useAddk8DeploymentMutation,
  useDeleteDeploymentMutation,
  useDeleteImageMutation,
  useDeleteTeamDeploymentMutation,
  useGetAgentDeploymentsQuery,
  useGetAvailableImagesQuery,
  useGetTeamDeploymentsQuery,
  useRemovek8DeploymentMutation,
  useUpdateAgentDeploymentMutation,
  useUpdateTeamDeploymentMutation,
} from "../../services/deploymentService";
import AddNewImage from "./AddNewImage";
import { useGetAgentsQuery } from "../../services/agentsService";
import AddNewDeployment from "./AddNewDeployment";
import { useGetWorkforcesQuery } from "../../services/workforceService";
import AddNewBuild from "./AddNewBuild";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const DeploymentScreen = () => {
  // const navigate = useNavigate();
  const currentSpace = useSelector((state) => state.defaultSpace.data);

  // const [addAction, addActionInfo] = useAddActionMutation();

  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [activeTab, setActiveTab] = useState("deployments");
  const [deploymentType, setDeploymentType] = useState("agent");

  const [showAddNewImages, setShowAddNewImages] = useState(false);
  const [showAddNewDeployment, setShowAddNewDeployment] = useState(false);
  const [showAddNewBuild, setShowAddNewBuild] = useState(false);

  // for env Variables
  // const [showAddNewImage, setShowAddNewImage] = useState(false);
  const [editImage, setEditImage] = useState(null);
  const [editDeployment, setEditDeployment] = useState(null);
  const [buildObj, setBuildObj] = useState(null);

  const {
    data: workforceData,
    error: workforceError,
    isFetching: workforceFetching,
  } = useGetWorkforcesQuery(currentSpace);

  const {
    data: agentData,
    error: agentError,
    isFetching: agentFetching,
  } = useGetAgentsQuery(currentSpace);

  const {
    data: availableImages,
    error: availableImagesError,
    isFetching: availableImagesFetching,
  } = useGetAvailableImagesQuery();

  const {
    data: agentDeploymentsData,
    error: agentDeploymentsError,
    isFetching: agentDeploymentsFetching,
    refetch: refetchAgentDeployments,
  } = useGetAgentDeploymentsQuery(undefined, {
    skip: deploymentType !== "agent",
  });

  const {
    data: teamDeploymentsData,
    error: teamDeploymentsError,
    isFetching: teamDeploymentsFetching,
    refetch: refetchTeamDeployments,
  } = useGetTeamDeploymentsQuery(undefined, {
    skip: deploymentType === "agent",
  });

  const deploymentsData =
    deploymentType === "agent" ? agentDeploymentsData : teamDeploymentsData;
  const deploymentsError =
    deploymentType === "agent" ? agentDeploymentsError : teamDeploymentsError;
  const deploymentsFetching =
    deploymentType === "agent"
      ? agentDeploymentsFetching
      : teamDeploymentsFetching;

  const [deleteImage] = useDeleteImageMutation();
  const [deleteDeployment] = useDeleteDeploymentMutation();
  const [deleteTeamDeployment] = useDeleteTeamDeploymentMutation();

  const [addK8Deploy, addK8DeployInfo] = useAddk8DeploymentMutation();

  const [removeK8Deploy, removeK8DeployInfo] = useRemovek8DeploymentMutation();

  const [updateAgentDeployment, updateAgentDeploymentInfo] =
    useUpdateAgentDeploymentMutation();

  const [updateTeamDeployment, updateTeamDeploymentInfo] =
    useUpdateTeamDeploymentMutation();

  // const [addK8Deploy, addK8DeployInfo] = useAddk8DeploymentMutation();

  const confirmImageDelete = useConfirmAction({ action: deleteImage });
  const confirmDeploymentDelete = useConfirmAction({
    action:
      deploymentType === "agent" ? deleteDeployment : deleteTeamDeployment,
  });

  const isPlatformAdmin = isPlatformAdminUser(currentSpace);
  const currentRole = getCurrentRole(currentSpace);
  const isAllowed = currentRole["space_admin"] || isPlatformAdmin;

  const handleDeploymentDelete = useCallback(
    (id) => {
      alert("deleted");
      // confirmDeploymentDelete({ deployment_id: id });
    },
    [confirmDeploymentDelete]
  );

  const handleImageDelete = useCallback(
    (id) => {
      confirmImageDelete({ image_id: id });
    },
    [confirmImageDelete]
  );

  const handleEdit = useCallback(
    (AVAILABLE_IMAGE_ID, IMAGE_NAME, ACTIVE, RELEASE_NOTES) => {
      setEditImage({ AVAILABLE_IMAGE_ID, IMAGE_NAME, ACTIVE, RELEASE_NOTES });
      setShowAddNewImages(true);
    },
    []
  );

  const handleDeploymentEdit = useCallback(
    (data, deploymentId, deploymentType) => {
      setEditDeployment({ data, deploymentId, deploymentType });
      setShowAddNewDeployment(true);
    },
    []
  );

  const handleBuild = useCallback(
    (action, deployment_id, config, rowData) => {
      const deployment = structuredClone(rowData);
      const body = structuredClone(config);

      const image = availableImages?.find(
        (img) => img.AVAILABLE_IMAGE_ID === Number(body.image)
      );

      body["object_id"] = Number(body["object_id"]);
      body["deployment_id"] = String(deployment_id);
      body["space_id"] = String(body.space_id);

      body["image"] = image.IMAGE_NAME;
      deployment.CONFIG["status"] = action === "undeploy" ? false : true;

      deployment["AVAILABLE_IMAGE_ID"] = String(
        deployment["AVAILABLE_IMAGE_ID"]
      );

      if (body.object_type === "agent") {
        deployment["AGENT_ID"] = String(deployment["AGENT_ID"]);
        deployment["AGENT_DEPLOYMENT_ID"] = String(
          deployment["AGENT_DEPLOYMENT_ID"]
        );
      } else {
        deployment["WORKFORCE_ID"] = String(deployment["WORKFORCE_ID"]);
        deployment["TEAM_DEPLOYMENT_ID"] = String(
          deployment["TEAM_DEPLOYMENT_ID"]
        );
      }

      body.object_type === "agent"
        ? updateAgentDeployment(deployment)
        : updateTeamDeployment(deployment);

      action === "undeploy" ? removeK8Deploy(body) : addK8Deploy(body);
    },
    [
      addK8Deploy,
      availableImages,
      removeK8Deploy,
      updateAgentDeployment,
      updateTeamDeployment,
    ]
  );

  const filteredImages = useFilteredData(
    availableImages,
    searchText,
    filterByVal
  );

  const filteredDeployments = useSearchFilter(
    deploymentsData,
    searchText,
    filterByVal
  );

  const [deleteDatabase] = useDeleteActionMutation();
  const confirmDelete = useConfirmDelete(deleteDatabase);

  const imageColumns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "AVAILABLE_IMAGE_ID",
      },
      {
        Header: "IMAGE",
        accessor: "IMAGE_NAME",
      },
      {
        Header: "NOTES",
        accessor: "RELEASE_NOTES",
      },
      {
        Header: "ACTIVE",
        accessor: "ACTIVE",
        Cell: ({ cell }) => (cell.row.original.ACTIVE ? "True" : "False"),
      },

      {
        Header: "Actions",
        Cell: ({ cell }) => {
          return (
            <div style={{ width: "120px" }}>
              <span
                title={
                  !isPlatformAdmin && cell.row.original.space_id === -1
                    ? `You don't have edit permission`
                    : ""
                }
                className={
                  !isPlatformAdmin && cell.row.original.space_id === -1
                    ? "permission-disabled"
                    : ""
                }
              >
                <Link
                  to={`#`}
                  className="text-body"
                  onClick={() =>
                    handleEdit(
                      cell.row.original.AVAILABLE_IMAGE_ID,
                      cell.row.original.IMAGE_NAME,
                      cell.row.original.ACTIVE,
                      cell.row.original.RELEASE_NOTES
                    )
                  }
                >
                  <i className="ti ti-edit ti-sm me-2" />
                </Link>
              </span>

              <AccessWrapper hasAccess="delete">
                <Link
                  to="#"
                  className="text-body delete-record"
                  onClick={() =>
                    handleImageDelete(cell.row.original.AVAILABLE_IMAGE_ID)
                  }
                >
                  <i className="ti ti-trash ti-sm mx-2" />
                </Link>
              </AccessWrapper>
            </div>
          );
        },
      },
    ],
    [handleEdit, handleImageDelete, isPlatformAdmin]
  );

  const deploymentColumns = React.useMemo(
    () => [
      {
        Header: "DEPLOYMENT ID",
        accessor:
          deploymentType === "agent"
            ? "AGENT_DEPLOYMENT_ID"
            : "TEAM_DEPLOYMENT_ID",
      },
      {
        Header: "IMAGE ID",

        accessor: (row) => {
          const image = availableImages?.find(
            (img) => img.AVAILABLE_IMAGE_ID === Number(row.AVAILABLE_IMAGE_ID)
          );
          return image ? image.IMAGE_NAME : "N/A"; // Fallback if no matching image is found
        },
      },
      {
        Header: deploymentType === "agent" ? "AGENT NAME" : "WORKFORCE NAME",

        accessor: (row) => {
          if (deploymentType === "agent") {
            return (
              agentData?.find(
                (agent) => agent.agent_id === Number(row.AGENT_ID)
              )?.name || "N/A"
            );
          } else {
            return (
              workforceData?.find(
                (workforce) =>
                  workforce.workforce_id === Number(row.WORKFORCE_ID)
              )?.title || "N/A"
            );
          }
        },
      },
      // {
      //   Header: "ENVIRONMENT",
      //   accessor: "ENVIRONMENT_NAME",
      // },
      {
        Header: "REPLICA SET",
        accessor: "REPLICA_SET",
      },
      {
        Header: "Actions",
        Cell: ({ cell }) => {
          const rowData = cell.row.original;
          const deploymentId =
            deploymentType === "agent"
              ? rowData.AGENT_DEPLOYMENT_ID
              : rowData.TEAM_DEPLOYMENT_ID;

          const agentID =
            deploymentType === "agent"
              ? rowData.AGENT_ID
              : rowData.WORKFORCE_ID;

          return (
            <div style={{ width: "120px", display: "flex" }}>
              <AccessWrapper hasAccess="edit">
                {addK8DeployInfo.isLoading === deploymentId ? (
                  <div
                    className="spinner-border spinner-border-sm text-primary"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : rowData.CONFIG?.status ? (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-deploy-${deploymentId}`}>
                          Pod Status
                        </Tooltip>
                      }
                    >
                      <Link
                        to={`${agentID}-d-${deploymentId}`}
                        className="text-body"
                      >
                        <i class="ti ti-link ti-sm mx-2"></i>
                      </Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-undeploy-${deploymentId}`}>
                          Undeploy
                        </Tooltip>
                      }
                    >
                      <Link
                        to="#"
                        className="text-body"
                        onClick={() =>
                          handleBuild(
                            "undeploy",
                            deploymentId,
                            rowData.CONFIG.build,
                            rowData
                          )
                        }
                      >
                        <i className="ti ti-rocket-off ti-sm mx-2"></i>
                      </Link>
                    </OverlayTrigger>
                  </>
                ) : (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-deploy-${deploymentId}`}>
                          Pod Status
                        </Tooltip>
                      }
                    >
                      <Link
                        to={`${agentID}-d-${deploymentId}`}
                        className="text-body"
                        style={{ visibility: "hidden" }}
                      >
                        <i class="ti ti-link ti-sm mx-2"></i>
                      </Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-deploy-${deploymentId}`}>
                          Deploy
                        </Tooltip>
                      }
                    >
                      <Link
                        to="#"
                        className="text-body"
                        onClick={() =>
                          handleBuild(
                            "deploy",
                            deploymentId,
                            rowData.CONFIG.build,
                            rowData
                          )
                        }
                      >
                        <i className="ti ti-rocket ti-sm mx-2"></i>
                      </Link>
                    </OverlayTrigger>
                  </>
                )}
              </AccessWrapper>

              <AccessWrapper hasAccess="edit">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-delete-${deploymentId}`}>
                      edit
                    </Tooltip>
                  }
                >
                  <Link
                    to={`#`}
                    className="text-body"
                    onClick={() =>
                      handleDeploymentEdit(
                        rowData,
                        deploymentId,
                        deploymentType
                      )
                    }
                  >
                    <i className="ti ti-edit ti-sm mx-2" />
                  </Link>
                </OverlayTrigger>
              </AccessWrapper>

              <AccessWrapper hasAccess="delete">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-delete-${deploymentId}`}>
                      Delete
                    </Tooltip>
                  }
                >
                  <Link
                    to="#"
                    className="text-body delete-record"
                    onClick={() => handleDeploymentDelete(deploymentId)}
                  >
                    <i className="ti ti-trash ti-sm mx-2"></i>
                  </Link>
                </OverlayTrigger>
              </AccessWrapper>
            </div>
          );
        },
      },
    ],
    [
      addK8DeployInfo.isLoading,
      agentData,
      availableImages,
      deploymentType,
      handleBuild,
      handleDeploymentDelete,
      handleDeploymentEdit,
      workforceData,
    ]
  );
  // const filteredPosts = useFilteredData(data, searchText, filterByVal);

  const handleAddNew = () => {
    setShowAddNewImages(true);
  };

  const handleAddNewDeployment = () => {
    setEditDeployment(null);
    setShowAddNewDeployment(true);
    // setShowAddNewKey(true);
  };

  useEffect(() => {
    if (addK8DeployInfo.isSuccess) {
      const { message, details } = addK8DeployInfo.data || {}; // Extract response data

      MySwal.fire({
        title: "Success!",
        html: `
          <p><strong>Namespace:</strong> ${details?.namespace || "N/A"}</p>
          <p><strong>Name:</strong> ${details?.name || "N/A"}</p>
          <p><strong>Image:</strong> ${details?.image || "N/A"}</p>
          <p><strong>URL Path:</strong> <a href="${
            details?.url_path
          }" target="_blank">${details?.url_path}</a></p>
        `,
        icon: "success",
        confirmButtonText: "OK",
      });
    } else if (addK8DeployInfo.isError) {
      MySwal.fire({
        title: "Error",
        text: "Operation Failed.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }, [
    addK8DeployInfo.data,
    addK8DeployInfo.isError,
    addK8DeployInfo.isSuccess,
  ]);

  useEffect(() => {
    if (removeK8Deploy.isSuccess) {
      MySwal.fire({
        title: "Success!",
        text: "Undeployment successful!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else if (removeK8Deploy.isError) {
      MySwal.fire({
        title: "",
        text: "Operation Failed.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }, [removeK8Deploy.isError, removeK8Deploy.isSuccess]);

  return (
    <ContentWrapper>
      <Banner>Deployment</Banner>

      <div className="card mt-5">
        <div
          className="card-header flex-column flex-md-row"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="head-label text-center">
            <h5 className="card-title mb-0">
              {activeTab === "deployments" ? "Deployments" : "Available Images"}
            </h5>
          </div>
          <div className="dt-action-buttons text-end pt-6 pt-md-0">
            <div className="">
              <div className="btn-group">
                {isPlatformAdmin && (
                  <button
                    className="btn btn-secondary buttons-collection btn-label-primary me-4 waves-effect waves-light border-none 
    d-flex align-items-center justify-content-center gap-1"
                    tabIndex={0}
                    aria-controls="DataTables_Table_0"
                    type="button"
                    aria-haspopup="dialog"
                    aria-expanded="false"
                    onClick={() =>
                      activeTab === "deployments"
                        ? setActiveTab("available_images")
                        : setActiveTab("deployments")
                    }
                  >
                    {activeTab === "deployments" ? (
                      <i className="ti ti-brand-vscode" />
                    ) : (
                      <i className="ti ti-rocket ti-xs" />
                    )}
                    <span className="d-none d-sm-inline-block">
                      {activeTab === "deployments"
                        ? "List Available Images"
                        : "List Deployments"}
                    </span>
                  </button>
                )}
              </div>
              {!agentDeploymentsFetching &&
                !agentFetching &&
                !workforceFetching &&
                !teamDeploymentsFetching &&
                !availableImagesFetching && (
                  <>
                    {activeTab === "deployments" ? (
                      <AccessWrapper hasAccess="create">
                        <AddNewBtn handleAddNew={handleAddNewDeployment}>
                          Add Deployment
                        </AddNewBtn>
                      </AccessWrapper>
                    ) : availableImagesFetching ? (
                      ""
                    ) : (
                      <span
                        title={
                          isAllowed ? "" : 'You don"t have create permission'
                        }
                        className={isAllowed ? "" : "permission-disabled"}
                      >
                        <AddNewBtn handleAddNew={handleAddNew}>
                          Add New Image
                        </AddNewBtn>
                      </span>
                    )}
                  </>
                )}
            </div>
          </div>
        </div>
        <hr class="my-0"></hr>

        <div className="row mx-2 my-3">
          <div className="col-md-2 m-auto">
            <div className={styles.postsPerPage}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
              />
            </div>
          </div>

          <div className="col-md-10">
            <div
              className={`dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0 `}
            >
              {activeTab === "deployments" && (
                <div
                  className="invoice_status gap-5"
                  style={{ margin: "0 25px", width: "20%" }}
                >
                  <select
                    id="UserRole"
                    className="form-select"
                    value={deploymentType}
                    onChange={(e) => setDeploymentType(e.target.value)}
                  >
                    <option value="agent" className="text-capitalize">
                      Agents
                    </option>
                    <option value="teams" className="text-capitalize">
                      Teams
                    </option>
                  </select>
                </div>
              )}

              <div className={styles.tableFilter}>
                <SearchFilter
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </div>
            </div>
          </div>
        </div>

        {activeTab === "deployments" ? (
          deploymentsFetching ||
          availableImagesFetching ||
          agentFetching ||
          workforceFetching ? (
            <div className={styles.cubeLoader}>
              {" "}
              <CubeSpinner />
            </div>
          ) : deploymentsError ? (
            <>
              <ErrorAlert>{getStandardErrMsg(deploymentsError)}</ErrorAlert>
            </>
          ) : (
            <ReactTable
              columns={deploymentColumns}
              data={filteredDeployments}
              pageRows={postsPerPage}
            />
          )
        ) : availableImagesFetching ? (
          <div className={styles.cubeLoader}>
            {" "}
            <CubeSpinner />
          </div>
        ) : availableImagesError ? (
          <>
            <ErrorAlert>
              Some error occurred. Please contact administrator
            </ErrorAlert>
          </>
        ) : (
          <>
            <ReactTable
              columns={imageColumns}
              data={filteredImages}
              pageRows={postsPerPage}
            />
          </>
        )}
      </div>

      {showAddNewImages && (
        <AddNewImage
          setShowAddNewImage={setShowAddNewImages}
          editImage={editImage}
          // setEditUser={setEditUser}
        />
      )}

      {showAddNewDeployment && (
        <AddNewDeployment
          setShowAddNewDeployment={setShowAddNewDeployment}
          editDeployment={editDeployment}
          availableImages={availableImages}
          agentData={agentData}
          workforceData={workforceData}
          deploymentType={deploymentType}
        />
      )}
      {showAddNewBuild && (
        <AddNewBuild
          buildObj={buildObj}
          setShowAddNewBuild={setShowAddNewBuild}
        />
      )}
    </ContentWrapper>
  );
};

export default DeploymentScreen;
