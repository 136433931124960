import React, { useEffect, useState } from "react";
import CurrentStepHeading from "../../Components/CurrentStepHeading/CurrentStepHeading";

import FieldWrapper from "../../Components/FieldWrapper/FieldWrapper";
import InputField, {
  RadioField,
  SelectInput,
  Textarea,
} from "../../Components/Input/Input";
import { Col, Row } from "react-bootstrap";
import {
  LoadingButton,
  PrimaryButton,
  SecondaryButton,
} from "../../Components/Buttons/Buttons";
import FullscreenEditor from "../../Components/FullScreenEditor/FullScreenEditor.jsx";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { generateAgentConfig } from "./generateAgentConfig";
import {
  actionOptValues,
  agentFrameworkTypeOptions,
  humanOptionValue,
  modelOptValues,
} from "../../utils/options";
import { jsonEditorOptions } from "../../utils/jsonEditorOption";
import CodeEditor from "../../Components/TextEditor/TextEditor.jsx";
import Tabs from "../../Components/Tabs/Tabs.jsx";
import PythonEditor from "../../Components/PythonEditor/PythonEditor.jsx";
import BasicSettings from "./BasicSettings.jsx";

const animatedComponents = makeAnimated();

const tabData = [
  {
    id: "home",
    title: "Basic Setting",
    content: <p>Basic settings content here.</p>,
  },
  {
    id: "profile",
    title: "Structured Output",
    content: <p>Advanced structured output content.</p>,
  },
];

const AgentStep2 = ({
  agent,
  showValue,
  handleEditorDidMount,
  addAgentInfo,
  updateAgentInfo,
  agentConfig,
  collectionData,
  isFetchingCollection,
  modelData,
  actionData,
  isFetchingModel,
  isFetchingAction,
  handlePrev,
  setAgentConfig,
  error,
  advancedStrOpt,
  setAdvancedStrOpt,
}) => {
  const [viewConfig, setViewConfig] = useState(false);

  const formValues = generateAgentConfig(agentConfig);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setAgentConfig((prevConfig) => {
      if (name === "use_llm_config") {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            use_llm_config: checked,
            llm_config: checked
              ? {
                  cache_seed: null,
                  config_list: [],
                  temperature: 0.1,
                  timeout: null,
                }
              : false,
          },
        };
      } else if (name === "use_code_execution_config") {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            use_code_execution_config: checked,
            code_execution_config: checked
              ? {
                  use_docker: false,
                  work_dir: null,
                }
              : false,
          },
        };
      }
      return prevConfig;
    });
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    let finalValue = type === "checkbox" ? checked : value;

    if (type === "number") {
      finalValue = parseFloat(value);
    }

    setAgentConfig((prevConfig) => {
      let updatedConfig = { ...prevConfig };

      if (name in prevConfig) {
        finalValue =
          name === "name"
            ? finalValue.replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_]/g, "")
            : finalValue;
        updatedConfig[name] = finalValue;
      } else if (name in prevConfig.config) {
        updatedConfig.config = { ...prevConfig.config, [name]: finalValue };
      } else if (
        prevConfig.config.llm_config &&
        typeof prevConfig.config.llm_config === "object" &&
        name in prevConfig.config.llm_config
      ) {
        updatedConfig.config = {
          ...prevConfig.config,
          llm_config: {
            ...prevConfig.config.llm_config,
            [name]: finalValue,
          },
        };
      } else if (
        prevConfig.config.code_execution_config &&
        typeof prevConfig.config.code_execution_config === "object" &&
        name in prevConfig.config.code_execution_config
      ) {
        updatedConfig.config = {
          ...prevConfig.config,
          code_execution_config: {
            ...prevConfig.config.code_execution_config,
            [name]: finalValue,
          },
        };
      } else {
        // 🛠 If the key is missing, add it under config
        updatedConfig.config = {
          ...prevConfig.config,
          [name]: finalValue,
        };
      }

      return updatedConfig;
    });
  };

  // useEffect(() => {
  //   setAgentConfig((prevConfig) => ({
  //     ...prevConfig,
  //     config: {
  //       ...prevConfig.config,
  //       structured_response: "",
  //     },
  //   }));
  // }, [advancedStrOpt]);

  const modelOptions = modelOptValues(modelData, formValues.configList);

  const modelDefaultValue = [
    ...modelOptions.filter((option) => option.isSelected),
  ];

  const actionOptions = actionOptValues(actionData, formValues.skills);

  const actionDefaultValue = [
    ...actionOptions.filter((option) => option.isSelected), // Preselected options
  ];

  const collectionOptions = collectionData?.map(
    ({ collection_id, collection_name }) => ({
      value: collection_id,
      label: collection_name,
    })
  );

  collectionOptions?.unshift({
    value: "",
    label: "Select",
  });

  const handleModelChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setAgentConfig((prevAgentConfig) => ({
      ...prevAgentConfig,
      config: {
        ...prevAgentConfig.config,
        llm_config: {
          ...prevAgentConfig.config.llm_config,
          config_list: selectedValues,
        },
      },
    }));
  };

  const handleActionChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setAgentConfig((prevAgentConfig) => ({
      ...prevAgentConfig,
      skills: selectedValues,
    }));
  };

  const handleEditorChange = (value) => {
    try {
      const parsedValue = JSON.parse(value);
      setAgentConfig(parsedValue);
    } catch (error) {
      console.error("Invalid JSON input", error);
    }
  };

  const getClassOptions = () => {
    if (!advancedStrOpt) return [];

    // Match all class definitions, whether they inherit from a base class or not
    const classRegex = /class\s+(\w+)(?:\s*\([^)]*\))?:/g;
    const matches = [...advancedStrOpt.matchAll(classRegex)];

    return matches.map((match) => ({
      value: match[1],
      label: match[1],
    }));
  };

  const handleClassChange = (selectedOption) => {
    setAgentConfig((prevAgentConfig) => ({
      ...prevAgentConfig,
      config: {
        ...prevAgentConfig.config,
        structured_output: selectedOption?.value || null,
      },
    }));
  };

  const handleAdvancedStrOptChange = (e) => {
    setAdvancedStrOpt(e.target.value);
    setAgentConfig((prevConfig) => ({
      ...prevConfig,
      config: {
        ...prevConfig.config,
        structured_response: "",
      },
    }));
  };

  return (
    <>
      {agent.framework === "pydantic-ai" ? (
        <Tabs className="custom-tabs">
          <div id="home" title="Basic Setting">
            <BasicSettings
              agent={agent}
              formValues={formValues}
              handleChange={handleChange}
              handleCheckboxChange={handleCheckboxChange}
              handlePrev={handlePrev}
              showValue={showValue}
              error={error}
              options={options}
              modelOptions={modelOptions}
              actionOptions={actionOptions}
              agentConfig={agentConfig}
              isFetchingModel={isFetchingModel}
              isFetchingAction={isFetchingAction}
              viewConfig={viewConfig}
              setViewConfig={setViewConfig}
              handleEditorChange={handleEditorChange}
              handleEditorDidMount={handleEditorDidMount}
              jsonEditorOptions={jsonEditorOptions}
              getClassOptions={getClassOptions}
              isFetchingCollection={isFetchingCollection}
              collectionOptions={collectionOptions}
              addAgentInfo={addAgentInfo}
              updateAgentInfo={updateAgentInfo}
              handleModelChange={handleModelChange}
              handleActionChange={handleActionChange}
              modelDefaultValue={modelDefaultValue}
              actionDefaultValue={actionDefaultValue}
            />
          </div>
          <div id="profile" title="Structured Output" className="mt-3">
            <PythonEditor
              value={advancedStrOpt}
              onChange={(e) => handleAdvancedStrOptChange(e)}
              defaultTheme="dark"
              height="40vh"
            />
          </div>
        </Tabs>
      ) : (
        <BasicSettings
          agent={agent}
          formValues={formValues}
          handleChange={handleChange}
          handleCheckboxChange={handleCheckboxChange}
          handlePrev={handlePrev}
          showValue={showValue}
          error={error}
          options={options}
          modelOptions={modelOptions}
          actionOptions={actionOptions}
          agentConfig={agentConfig}
          isFetchingModel={isFetchingModel}
          isFetchingAction={isFetchingAction}
          viewConfig={viewConfig}
          setViewConfig={setViewConfig}
          handleEditorChange={handleEditorChange}
          handleEditorDidMount={handleEditorDidMount}
          jsonEditorOptions={jsonEditorOptions}
          getClassOptions={getClassOptions}
          isFetchingCollection={isFetchingCollection}
          collectionOptions={collectionOptions}
          addAgentInfo={addAgentInfo}
          updateAgentInfo={updateAgentInfo}
          handleModelChange={handleModelChange}
          handleActionChange={handleActionChange}
          modelDefaultValue={modelDefaultValue}
          actionDefaultValue={actionDefaultValue}
        />
      )}
    </>
  );
};

const options = humanOptionValue();

export default AgentStep2;
