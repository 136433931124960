import React, { useState, useRef, useEffect } from "react";
import Editor from "@monaco-editor/react";
import {
  FiMaximize,
  FiMinimize,
  FiSave,
  FiDownload,
  FiSettings,
} from "react-icons/fi";

const validatePythonSchema = (code) => {
  if (!code.includes("class") || !code.includes("BaseModel")) {
    return "Invalid Pydantic schema: Missing 'class' or 'BaseModel'.";
  }
  return null;
};

const CodeEditor = ({
  value = "",
  onChange,
  rows = 10,
  defaultTheme = "light",
  height = null,
  width = "100%",
  language = "python",
  title = "Code Editor",
  validateCode = null,
  defaultFileName = "code.txt",
}) => {
  const [error, setError] = useState(null);
  const [theme, setTheme] = useState(defaultTheme);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [editorSettings, setEditorSettings] = useState({
    fontSize: 14,
    showLineNumbers: true,
    wordWrap: "on",
  });
  const normalEditorRef = useRef(null);
  const fullscreenEditorRef = useRef(null);
  const settingsRef = useRef(null);
  const editorRef = useRef(null);

  const [editorContent, setEditorContent] = useState(value);

  // Update editorContent when value prop changes
  useEffect(() => {
    setEditorContent(value);
  }, [value]);

  // Sync editor content between normal and fullscreen modes
  useEffect(() => {
    const syncContent = () => {
      if (
        isFullscreen &&
        normalEditorRef.current &&
        fullscreenEditorRef.current
      ) {
        const content = normalEditorRef.current.getValue();
        fullscreenEditorRef.current.setValue(content);
      } else if (
        !isFullscreen &&
        normalEditorRef.current &&
        fullscreenEditorRef.current
      ) {
        const content = fullscreenEditorRef.current.getValue();
        normalEditorRef.current.setValue(content);
      }
    };

    // Add a small delay to ensure editors are mounted
    const timeoutId = setTimeout(syncContent, 100);
    return () => clearTimeout(timeoutId);
  }, [isFullscreen]);

  const handleEditorChange = (newValue) => {
    setEditorContent(newValue);
    onChange?.({ target: { value: newValue } });
  };

  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  const handleSave = () => {
    const editor = isFullscreen
      ? fullscreenEditorRef.current
      : normalEditorRef.current;
    if (editor) {
      const content = editor.getValue();
      const blob = new Blob([content], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = defaultFileName;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  // Handle editor mounting
  //   const handleEditorMount = (editor, isFullscreen) => {
  //     if (isFullscreen) {
  //       fullscreenEditorRef.current = editor;
  //       if (normalEditorRef.current) {
  //         editor.setValue(normalEditorRef.current.getValue());
  //       }
  //     } else {
  //       normalEditorRef.current = editor;
  //       if (fullscreenEditorRef.current) {
  //         editor.setValue(fullscreenEditorRef.current.getValue());
  //       }
  //     }
  //   };

  const handleEditorMount = (editor) => {
    editorRef.current = editor;
    editor.setValue(editorContent);
  };

  useEffect(() => {
    const layoutEditor = () => {
      const editor = isFullscreen
        ? fullscreenEditorRef.current
        : normalEditorRef.current;
      if (editor) {
        editor.layout();
      }
    };

    layoutEditor();
  }, [isFullscreen]);

  const editorProps = {
    language: language,
    value: editorContent,
    onChange: handleEditorChange,
    theme: theme === "dark" ? "vs-dark" : "light",
    options: {
      minimap: { enabled: false },
      wordWrap: editorSettings.wordWrap,
      fontSize: editorSettings.fontSize,
      lineNumbers: editorSettings.showLineNumbers ? "on" : "off",
      quickSuggestions: true,
      parameterHints: {
        enabled: true,
      },
      snippetSuggestions: "inline",
      wordBasedSuggestions: true,
      semanticHighlighting: true,
    },
  };

  const renderEditorControls = () => (
    <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
      {/* Save Button */}
      <button
        onClick={handleSave}
        style={{
          background: "none",
          border: "none",
          cursor: "pointer",
          padding: "4px",
          color: theme === "dark" ? "#fff" : "#333",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        title="Save Code"
      >
        <FiSave size={20} />
      </button>

      {/* Settings Button */}
      <div style={{ position: "relative" }} ref={settingsRef}>
        <button
          onClick={() => setShowSettings(!showSettings)}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            padding: "4px",
            color: theme === "dark" ? "#fff" : "#333",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          title="Editor Settings"
        >
          <FiSettings size={20} />
        </button>
        {showSettings && renderSettings()}
      </div>

      {/* Theme Toggle */}
      <label
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          cursor: "pointer",
        }}
      >
        <span
          style={{
            fontSize: "12px",
            color: theme === "dark" ? "#fff" : "#333",
          }}
        >
          {theme === "light" ? "Light" : "Dark"} Mode
        </span>
        <div style={{ position: "relative", width: "40px", height: "20px" }}>
          <input
            type="checkbox"
            checked={theme === "dark"}
            onChange={() => setTheme(theme === "light" ? "dark" : "light")}
            style={{
              width: "40px",
              height: "20px",
              appearance: "none",
              backgroundColor: theme === "dark" ? "#555" : "#ddd",
              borderRadius: "20px",
              cursor: "pointer",
              outline: "none",
              transition: "background 0.3s",
            }}
          />
          <span
            style={{
              position: "absolute",
              width: "16px",
              height: "16px",
              backgroundColor: "#fff",
              borderRadius: "50%",
              top: "2px",
              left: "2px",
              transition: "transform 0.3s",
              transform:
                theme === "dark" ? "translateX(20px)" : "translateX(0)",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            }}
          ></span>
        </div>
      </label>

      {/* Fullscreen Button */}
      <button
        onClick={toggleFullscreen}
        style={{
          background: "none",
          border: "none",
          cursor: "pointer",
          padding: "4px",
          color: theme === "dark" ? "#fff" : "#333",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        title="Fullscreen"
      >
        {isFullscreen ? <FiMinimize size={20} /> : <FiMaximize size={20} />}
      </button>
    </div>
  );

  const renderSettings = () => (
    <div
      style={{
        position: "absolute",
        top: "100%",
        right: 0,
        backgroundColor: theme === "dark" ? "#2d2d2d" : "#fff",
        border: `1px solid ${theme === "dark" ? "#444" : "#ddd"}`,
        borderRadius: "4px",
        padding: "12px",
        zIndex: 1000,
        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        minWidth: "200px",
      }}
    >
      <div style={{ marginBottom: "8px" }}>
        <label
          style={{
            display: "block",
            marginBottom: "4px",
            color: theme === "dark" ? "#fff" : "#333",
          }}
        >
          Font Size
        </label>
        <input
          type="range"
          min="12"
          max="24"
          value={editorSettings.fontSize}
          onChange={(e) =>
            setEditorSettings({
              ...editorSettings,
              fontSize: parseInt(e.target.value),
            })
          }
          style={{ width: "100%" }}
        />
      </div>
      <div style={{ marginBottom: "8px" }}>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: theme === "dark" ? "#fff" : "#333",
          }}
        >
          <input
            type="checkbox"
            checked={editorSettings.showLineNumbers}
            onChange={(e) =>
              setEditorSettings({
                ...editorSettings,
                showLineNumbers: e.target.checked,
              })
            }
          />
          Show Line Numbers
        </label>
      </div>
      <div style={{ marginBottom: "8px" }}>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: theme === "dark" ? "#fff" : "#333",
          }}
        >
          <input
            type="checkbox"
            checked={editorSettings.wordWrap === "on"}
            onChange={(e) =>
              setEditorSettings({
                ...editorSettings,
                wordWrap: e.target.checked ? "on" : "off",
              })
            }
          />
          Word Wrap
        </label>
      </div>
    </div>
  );

  const editorHeight = height || `${rows * 20}px`;

  return (
    <div style={{ width }} className="mt-4">
      {!isFullscreen && (
        <div
          style={{
            border: "1px solid #ddd",
            borderRadius: "6px",
            overflow: "hidden",
            backgroundColor: theme === "dark" ? "#1e1e1e" : "#fff",
            padding: "10px",
            marginTop: "-35px",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <span
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: theme === "dark" ? "#fff" : "#333",
              }}
            >
              {title}
            </span>

            {renderEditorControls()}
          </div>

          <Editor
            height={editorHeight}
            {...editorProps}
            onMount={(editor) => handleEditorMount(editor, false)}
          />

          {error && (
            <div style={{ color: "red", fontSize: "12px", padding: "4px" }}>
              {error}
            </div>
          )}
        </div>
      )}

      {isFullscreen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: theme === "dark" ? "#1e1e1e" : "#fff",
            zIndex: 10000,
            padding: "10px 28px 0px 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "8px",
              padding: "0 10px",
            }}
          >
            <span
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: theme === "dark" ? "#fff" : "#333",
              }}
            >
              {title}
            </span>

            {renderEditorControls()}
          </div>

          <Editor
            height="calc(100vh - 50px)"
            {...editorProps}
            onMount={(editor) => handleEditorMount(editor, true)}
          />

          {error && (
            <div
              style={{
                color: "red",
                fontSize: "12px",
                padding: "4px",
                position: "fixed",
                bottom: 0,
              }}
            >
              {error}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// Python-specific editor component
const PythonEditor = (props) => (
  <CodeEditor
    {...props}
    language="python"
    title="Python Editor"
    validateCode={validatePythonSchema}
    defaultFileName="pydantic_model.py"
  />
);

export { CodeEditor, PythonEditor };
export default PythonEditor;
