import React, { useRef, useState } from "react";
import Banner from "../../Components/Banner/Banner";
import { Row } from "react-bootstrap";

import { useEffect } from "react";

import { useParams } from "react-router-dom";

import { CubeSpinner } from "../../Components/Spinners/Spinners";
import styles from "./agent.module.css";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import {
  useAddAgentMutation,
  useGetAgentsByIdQuery,
  useUpdateAgentMutation,
} from "../../services/agentsService";
import { useSelector } from "react-redux";

import { useGetCollectionsQuery } from "../../services/collectionService";

import useOperationFeedback from "../../utils/useOperationFeedback";
import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import {
  NewStepHeader,
  StepsHeader,
} from "../../Components/StepsHeader/StepsHeader";

import { useGetModelsQuery } from "../../services/modelsService";
import { useGetActionsQuery } from "../../services/actionsService";
import { getStandardErrMsg } from "../../utils/utils";
import { agentObj } from "./agentObj";

import AgentStep1 from "./AgentStep1";
import AgentStep2 from "./AgentStep2";
import BackButton from "../../Components/BackButton/BackButton";
import { isJSON } from "validator";

const AddNewAgent = () => {
  const { agent_id } = useParams();
  const currentSpace = useSelector((state) => state.defaultSpace.data);

  const { data: collectionData = [], isFetching: isFetchingCollection } =
    useGetCollectionsQuery(currentSpace);

  const { data: modelData = [], isFetching: isFetchingModel } =
    useGetModelsQuery(currentSpace);

  const { data: actionData = [], isFetching: isFetchingAction } =
    useGetActionsQuery(currentSpace);

  const skip = agent_id ? false : true;

  const { data, error, isFetching } = useGetAgentsByIdQuery(agent_id, {
    skip,
  });

  const editorRef = useRef();

  const [activeStep, setActiveStep] = useState(1);
  const [advancedStrOpt, setAdvancedStrOpt] = useState("");

  const [agent, setAgent] = useState({
    title: "",
    framework: "autogen",
    desc: "",
    stage: "",
    share: false,
    collection: "",
    type: "",
  });

  const [agentError, setAgentError] = useState({
    title: "",
    framework: "",
    stage: "",
  });

  const [agentConfigError, setAgentConfigError] = useState({
    model: "",
    name: "",
  });

  const [agentConfig, setAgentConfig] = useState(agentObj);

  useEffect(() => {
    if (agent_id && data) {
      // console.log("🚀 ~ useEffect ~ data:", data);
      setAgent({
        title: data.name,
        framework: data.framework,
        desc: data.description,
        stage: data.agent_versions[0].stage,
        share: JSON.parse(data.share?.toLowerCase()),
      });

      if (
        typeof data.agent_versions[0].config === "string" &&
        isJSON(data.agent_versions[0].config)
      ) {
        setAgentConfig(JSON.parse(data.agent_versions[0].config));

        if (
          data.agent_versions[0].advanced_structured_output &&
          typeof data.agent_versions[0].advanced_structured_output !==
            "object" &&
          isJSON(data.agent_versions[0].advanced_structured_output)
        ) {
          const advancedStrOptCode = JSON.parse(
            data.agent_versions[0].advanced_structured_output
          );
          // console.log(
          //   "🚀 ~ useEffect ~ advancedStrOptCode:",
          //   advancedStrOptCode
          // );

          setAdvancedStrOpt(advancedStrOptCode?.content);
        } else if (data.agent_versions[0].advanced_structured_output) {
          // console.log(data.agent_versions[0].advanced_structured_output);

          setAdvancedStrOpt(
            data.agent_versions[0].advanced_structured_output.content
          );
        }
      }
    }
  }, [data, agent_id]);

  const [addAgent, addAgentInfo] = useAddAgentMutation();

  const [updateAgent, updateAgentInfo] = useUpdateAgentMutation();

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;

    setTimeout(() => {
      if (editorRef.current) {
        const formatAction = editorRef.current.getAction(
          "editor.action.formatDocument"
        );
        if (formatAction) {
          formatAction.run();
        } else {
          console.error("Format document action is not available.");
        }
      }
    }, 300);
  };

  const handlePrev = () => {
    setActiveStep(1);
  };

  const handleValidation = () => {
    let valid = true;
    let newErrors = {};

    if (activeStep === 1) {
      for (const field in agentError) {
        newErrors[field] = "";
      }
      for (const field in agentError) {
        if (!agent[field]) {
          valid = false;
          newErrors[field] = `${
            field.charAt(0).toUpperCase() + field.slice(1)
          } is required.`;
        }
      }
      if (!valid) {
        setAgentError(newErrors);
      }
    } else if (activeStep === 2) {
      for (const field in agentConfigError) {
        newErrors[field] = "";
      }

      if (!agentConfig.name || agentConfig.name.trim() === "") {
        valid = false;
        newErrors.name = "Agent name is required";
      }
      if (agentConfig.config.llm_config.config_list.length <= 0) {
        valid = false;

        newErrors.model = "Please select at least one model";
      }

      setAgentConfigError(newErrors);
    }
    return valid;
  };

  const handleNext = () => {
    if (handleValidation()) {
      setActiveStep(activeStep + 1);
      setAgentError({
        title: "",
        framework: "",
        stage: "",
      });
    }
  };

  useOperationFeedback(addAgentInfo.isSuccess, addAgentInfo.isError, "/agents");
  useOperationFeedback(
    updateAgentInfo.isSuccess,
    updateAgentInfo.isError,
    "/agents"
  );

  function showValue() {
    if (handleValidation()) {
      setAgentConfig((prevSpace) => ({
        ...prevSpace,
        name: agent.title,
        description: agent.desc,
      }));

      const body = {
        agent_id: agent_id ? agent_id : null,
        agent_version_id: agent_id
          ? data.agent_versions[0].agent_version_id
          : null,
        version: "1.0",
        stage: agent.stage,
        space_id: agent_id ? data.space_id : currentSpace,
        name: agent.title,
        description: agent.desc,
        framework: agent.framework,
        share: agent.share.toString(),
        parent_id: 0,
        platform_default: true,
        config: agentConfig,
        type: agent.type,
        advanced_structured_output: { content: advancedStrOpt },
      };
      // console.log("🚀 ~ showValue ~ body:", body);

      agent_id
        ? updateAgent({ body: body, agent_id: agent_id })
        : addAgent(body);
    }
  }

  return (
    <ContentWrapper>
      <Banner>{agent_id ? "Edit" : "Add"} Agent</Banner>

      <Row className="mt-5">
        <BackButton />

        {isFetching ? (
          <div className={styles.cubeLoader}>
            {" "}
            <CubeSpinner />
          </div>
        ) : error ? (
          <>
            <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
          </>
        ) : (
          <div className="bs-stepper wizard-vertical vertical mt-2">
            <StepsHeader>
              <NewStepHeader
                activeStep={activeStep}
                stepNumber={1}
                stepTitle={"New Agent"}
                stepSubtitle={"Enter basic details"}
                handleClick={handlePrev}
              />
              <div className="line" />

              <NewStepHeader
                activeStep={activeStep}
                stepNumber={2}
                stepTitle={"Configurations"}
                stepSubtitle={"Enter agent configuration"}
                handleClick={handleNext}
              />
            </StepsHeader>

            <div className="bs-stepper-content">
              {activeStep === 1 && (
                <AgentStep1
                  agent={agent}
                  setAgent={setAgent}
                  error={agentError}
                  handleNext={handleNext}
                />
              )}

              {activeStep === 2 && (
                <AgentStep2
                  agent={agent}
                  showValue={showValue}
                  handleEditorDidMount={handleEditorDidMount}
                  addAgentInfo={addAgentInfo}
                  updateAgentInfo={updateAgentInfo}
                  agentConfig={agentConfig}
                  collectionData={collectionData}
                  isFetchingCollection={isFetchingCollection}
                  modelData={modelData}
                  actionData={actionData}
                  isFetchingModel={isFetchingModel}
                  isFetchingAction={isFetchingAction}
                  handlePrev={handlePrev}
                  setAgentConfig={setAgentConfig}
                  error={agentConfigError}
                  advancedStrOpt={advancedStrOpt}
                  setAdvancedStrOpt={setAdvancedStrOpt}
                />
              )}
            </div>
          </div>
        )}
      </Row>
    </ContentWrapper>
  );
};

export default AddNewAgent;
