import { useState } from "react";

const Tabs = ({ children, className = "" }) => {
  const tabsArray = children.map((child) => ({
    id: child.props.id,
    title: child.props.title,
    content: child.props.children,
  }));

  const [activeTab, setActiveTab] = useState(tabsArray[0].id);

  return (
    <div className={`tabs-container ${className}`}>
      {/* Tab Navigation */}
      <ul
        className={`nav nav-tabs ${className}`}
        role="tablist"
        style={{ background: "#f4f4f4", marginBottom: "3%", borderRadius: 0 }}
      >
        {tabsArray.map((tab) => (
          <li key={tab.id} className="nav-item" role="presentation">
            <button
              type="button"
              className={`nav-link waves-effect ${
                activeTab === tab.id ? "active" : ""
              }`}
              role="tab"
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.title}
            </button>
          </li>
        ))}
      </ul>

      {/* Tab Content */}
      <div className={`tab-content ${className}`}>
        {tabsArray.map(
          (tab) =>
            activeTab === tab.id && (
              <div key={tab.id} className="tab-pane fade show active">
                {tab.content}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default Tabs;
